'use client';

import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { cn } from '@noirproperties/ui/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';

type StickyMenuProps = {
  children: React.ReactNode;
  className?: string;
  scrollThreshold?: number;
  sticky: {
    className?: string;
  };
};

export default function StickyMenu({
  children,
  className,
  sticky = {
    className: '',
  },
  scrollThreshold = 100,
}: StickyMenuProps) {
  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const lastScrollY = useRef(0);

  const controlNavbar = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > scrollThreshold) {
      setIsSticky(true);
      setIsVisible(currentScrollY < lastScrollY.current);
    } else {
      setIsSticky(false);
      setIsVisible(true);
    }

    lastScrollY.current = currentScrollY;
  }, [scrollThreshold]);

  useEffect(() => {
    let rafId: number;
    const debouncedControlNavbar = () => {
      cancelAnimationFrame(rafId);
      rafId = requestAnimationFrame(controlNavbar);
    };

    window.addEventListener('scroll', debouncedControlNavbar);

    return () => {
      window.removeEventListener('scroll', debouncedControlNavbar);
      cancelAnimationFrame(rafId);
    };
  }, [controlNavbar]);

  return (
    <>
      <div className={cn('w-full', className)}>
        <div className="px-6 pt-2 lg:pe-0 lg:ps-8">{children}</div>
      </div>

      <AnimatePresence>
        {isSticky ? (
          <motion.div
            animate={{
              opacity: isVisible ? 1 : 0,
              y: isVisible ? 0 : -20,
              scale: isVisible ? 1 : 0.95,
            }}
            className={cn(
              'fixed left-0 top-0 z-50 w-full shadow-sm',
              sticky.className,
            )}
            exit={{ opacity: 0, y: -20, scale: 0.95 }}
            initial={{ opacity: 0, y: -20, scale: 0.95 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
          >
            <div className="mx-auto max-w-7xl px-6 pt-2 lg:pe-0 lg:ps-8">
              {children}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
}
